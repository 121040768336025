<script>
    import Layout from "../../../layouts/main.vue";
    import PageHeader from "@/components/page-header";
    import appConfig from "../../../../app.config";
    import axios from 'axios';
    import { jsPDF } from "jspdf";
    import serverConfig   from '@/helpers/config';
    import Swal from "sweetalert2";
    import { ref } from "vue";
    import moment from "moment";

    export default {
        page: {
            title: "Dettaglio documento",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        setup() {
            const active = ref(false);
            const toggleActive = () => {
            active.value = !active.value;
            };

            let tk = 'Bearer '+localStorage.getItem('tk')

            return { active, toggleActive, tk };
        },
        data() {
            return {
                UrlServer: serverConfig.EP,
                title: "Stampa documento",
                items: [
                  
                    {
                        text: "Ecommerce",
                        href: "/",
                    },
                    {
                        text: "Ordini",
                        href: "/ecommerce/orders",
                    },
                    {
                        text: "Dettaglio ordine",
                        href: "/ecommerce/order/"+this.$route.params.id+"/details",
                    },
                    {
                        text: "Stampa documento",
                        active: true,
                    },
                ],
                quoteDetails:{},
                amount: 875,
                billing_address: "305 S San Gabriel Blvd, California, United States - 91776",
                billing_name: "David Nichols",
                billing_phone: "+(123) 456-7890",
                billing_taxno: "12-3456789",
                card_holder_name: "David Nichols",
                card_number: "xxx xxxx xxxx 1234",
                company_address: "403 H Fan Faranh Blvd, London, United States",
                contact: 8987894122,
                country: "Brazil",
                date: "2021-04-06T16:28:00.000Z",
                discount: "53.99",
                email: "velzon@themesbrand.com",
                estimated_tax: "44.99",
                img: "assets/images/users/avatar-2.jpg",
                quoteId: "#VL25000351",
                name: "Diana Kohler",
                notes: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online. If account is not paid within 7 days the credits details supplied as confirmation of work undertaken will be charged the agreed quoted fee noted above.",
                payment_method: "Mastercard",
                postalcode: 91798,
                product_detail: [{
                    name: 'Urban Ladder Pashe Chair',
                    category: 'Furniture',
                    price: '160',
                    stock: '2'
                }, {
                    name: '350 ml Glass Grocery Container',
                    category: 'Kitchen Storage & Containers',
                    price: '39.96',
                    stock: '1'
                }],
                registration_no: "6561",
                shipping_address: "345 Elm Ave, Solvang California, United States - 91776",
                shipping_charge: "65.00",
                shipping_name: "Donald Palmer",
                shipping_phone: "+(234) 987-01234",
                shipping_taxno: "12-3456789",
                status: "Paid",
                sub_total: 359.96,
                total_amount: 415.96,
                website: "www.themesbrand.com",
            };
        },
        mounted() {
            if (this.$route.params.id) {
                axios.get(`${this.UrlServer}carts/completed/${this.$route.params.id}`, 
                { 
                    headers: { 'authorization':this.tk}
                }
                ).then(response=>{
                    this.quoteDetails = response.data
                }).catch((error) => {
                    if (error == "Error: Request failed with status code 401")
                    {
                        // this.alertSessionExp()
                    } else {
                        Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
                    }

                });
            }
            if (this.$route.params.id) {
                axios.get(`https://api-node.themesbrand.website/apps/invoice/${this.$route.params.id}`)
                    .then((data) => {
                        console.log(data.data.data)
                        data = data.data.data
                        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct",
                            "Nov", "Dec"
                        ];
                        var dd = new Date(data.date)
                        data.date = dd.getDate() + " " + monthNames[dd.getMonth()] + ", " + dd.getFullYear();
                        var hours = dd.getHours();
                        var minutes = dd.getMinutes();
                        var ampm = hours >= 12 ? 'PM' : 'AM';
                        hours = hours % 12;
                        hours = hours ? hours : 12; // the hour '0' should be '12'
                        hours = hours < 10 ? '0' + hours : hours;
                        minutes = minutes < 10 ? '0' + minutes : minutes;
                        var strTime = hours + ':' + minutes + ' ' + ampm;
                        data.time = strTime;

                        this.company_address = data.company_address;
                        this.postalcode = data.postalcode;
                        this.registration_no = data.registration_no;
                        this.email = data.email;
                        this.website = data.website;
                        this.contact = data.contact;
                        this.quoteId = data.quoteId;
                        this.product_detail = data.product_detail;
                        this.date = data.date;
                        this.time = data.time;
                        this.status = data.status;

                        var itemclass = data.status == 'Paid' ? 'badge-soft-success' : data.status == 'Cancel' ?
                            'badge-soft-danger' : data.status == 'Unpaid' ? 'badge-soft-warning' : data.status ==
                            'Refund' ? 'badge-soft-primary' : '';
                        console.log(itemclass)
                        document.getElementById('payment-status').classList.add(itemclass);

                        this.total_amount = data.total_amount;

                        this.billing_name = data.billing_name;
                        this.billing_address = data.billing_address;
                        this.billing_phone = data.billing_phone;
                        this.billing_taxno = data.billing_taxno;

                        this.shipping_name = data.shipping_name;
                        this.shipping_address = data.shipping_address;
                        this.shipping_phone = data.shipping_phone;
                        this.shipping_taxno = data.shipping_taxno;

                        this.payment_method = data.payment_method;
                        this.card_holder_name = data.card_holder_name;
                        this.card_number = data.card_number;
                        this.total_amount = data.total_amount;
                        this.notes = data.notes;

                    }).catch((er) => {
                        console.log(er)
                    });
            }
        },
        methods:{
            convertFromUnix(value){
                return  moment.unix(value).locale('it').format("LLL")
            },
            convertHTMLToPDF() {
                var doc = new jsPDF('p', 'pt', 'A4');
                var pdfjs = document.querySelector('#demo');
                // Convert HTML invoice template source into PDF in JavaScript   
                doc.html(pdfjs, {
                callback: function(doc) {
                doc.save("output.pdf");
                },
                margin: [12, 12, 12, 12],
                autoPaging: 'text',
                html2canvas: {
                    allowTaint: true,
                    dpi: 300,
                    letterRendering: true,
                    logging: false,
                    scale: .8
                    }
                });
            },
            checkVar(value){
                if (value) {
                    return typeof value
                }
            },
            formatPrice(value) {
                let val = (value/1).toFixed(2).replace('.',',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            getCart(){
                if ( this.$route.params.orx) {
                    axios.get(`${this.UrlServer}carts/completed/${this.$route.params.orx}`, 
                    { 
                        headers: { 'authorization':this.tk}
                    }
                    ).then(response=>{
                        this.orderList = response.data
                    }).catch((error) => {
                        if (error == "Error: Request failed with status code 401")
                        {
                            // this.alertSessionExp()
                        } else {
                            Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
                        }

                    });
                }
            },
        },
        components: {
            Layout,
            PageHeader,
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row justify-content-center">
            <div class="col-xxl-9">
                <div class="card" id="demo">
                    <div class="card-header border-bottom-dashed p-4">
                        
                        <div class="d-sm-flex">
                            <div class="flex-grow-1">
                                <img src="@/assets/images/logo-dark.png" class="card-logo card-logo-dark"
                                    alt="logo dark" height="27">
                                <img src="@/assets/images/logo-light.png" class="card-logo card-logo-light"
                                    alt="logo light" height="27">
                                <div class="mt-sm-5 mt-4">
                                    <!--
                                    <h6 class="text-muted text-uppercase fw-semibold">Indirizzo</h6>
                                    <p class="text-muted mb-1" id="address-details">California, United States</p>
                                    <p class="text-muted mb-0" id="zip-code"><span>Zip-code:</span> 90201</p>
                                    -->
                                </div>
                            </div>
                            <div class="flex-shrink-0 mt-sm-0 mt-3">
                               <!-- <h6><span class="text-muted fw-normal">Legal
                                        Registration No: </span>
                                    <span id="legal-register-no">{{registration_no}}</span>
                                </h6>
                                <h6><span class="text-muted fw-normal">Email: </span>
                                    <span id="email">{{email}}</span>
                                </h6>
                                <h6><span class="text-muted fw-normal">Website:</span> <a
                                        href="https://themesbrand.com/" class="link-primary" target="_blank"
                                        id="website">{{website}}</a></h6>
                                <h6 class="mb-0"><span class="text-muted fw-normal">Contact No: </span><span
                                        id="contact-no">{{contact}}</span></h6>-->
                            </div>
                        </div>
                    </div>
                    <!--end card-header-->
                    <div class="card-body p-4">
                        <div class="row g-3">
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Ordine/Quotazione</p>
                                <h5 class="fs-14 mb-0"><span id="quote-no">
                                    <div v-if="quoteDetails">
                                    <template v-if="quoteDetails.header">
                                        <strong>{{ quoteDetails.header.orderNumber }}/{{ quoteDetails.header.year }}</strong>
                                    </template>
                                    </div>
                                </span></h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Data</p>
                                <h5 class="fs-14 mb-0"><span id="quote-date" v-if="quoteDetails.header">
                                    <strong>{{ convertFromUnix(quoteDetails.header.unixDate) }}</strong>
                                </span> </h5>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Tipo documento</p>
                                <span class="badge fs-11" id="payment-status">
                                  
                                    <template v-if="quoteDetails.header">
                                        <h5 class="fs-14 mb-0"><span id="quote-status">
                                        <template v-if="quoteDetails.header.status=='completed'">
                                            Ordine
                                        </template>
                                        <template v-if="quoteDetails.header.status=='quotation'">
                                            Preventivo
                                        </template>   
                                        </span>
                                        </h5>                                     
                                    </template>
                                  
                                </span>
                            </div>
                            <!--end col-->
                            <div class="col-lg-3 col-6">
                                <p class="text-muted mb-2 text-uppercase fw-semibold">Totale</p>
                                <h5 class="fs-14 mb-0">&euro;<span id="total-amount">{{ formatPrice(quoteDetails.totalPrice) }}</span></h5>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4 border-top border-top-dashed">
                        <div class="row g-3">
                            <div class="col-sm-6" v-if="quoteDetails.user">
                                <h6 class="text-muted text-uppercase fw-semibold mb-3">Indirizzo di fatturazione</h6>
                                <p class="fw-medium mb-2" id="billing-name">{{quoteDetails.user.ragSoc}}</p>
                                <p class="text-muted mb-1" id="billing-address">{{quoteDetails.user.indirizzo}} - {{quoteDetails.user.citta}}</p>
                                <p class="text-muted mb-1" id="billing-phone">Tel: {{quoteDetails.user.phone}}</p>
                                <p class="text-muted mb-0" id="billing-tax">Email: {{quoteDetails.user.emailAzienda}}</p>
                                <p class="text-muted mb-0" id="billing-tax">P.Iva: {{quoteDetails.user.partitaIva}}</p>
                                <p class="text-muted mb-0" id="billing-tax">Codice U.: {{quoteDetails.user.codeDest}}</p>
                            </div>
                            <!--end col-->
                            <div class="col-sm-6" v-if="quoteDetails.user">
                                <h6 class="text-muted text-uppercase fw-semibold mb-3">Indirizzo di spedizione</h6>
                                <template v-if="quoteDetails.shipping">
                                    <p class="fw-medium mb-2" id="billing-name">{{quoteDetails.user.shipping.ragSoc}}</p>
                                    <p class="text-muted mb-1" id="billing-address">{{quoteDetails.user.shipping.indirizzo}} - {{quoteDetails.user.shipping.citta}}</p>
                                    <p class="text-muted mb-1" id="billing-phone">Tel: {{quoteDetails.user.shipping.phone}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">Email: {{quoteDetails.user.shipping.emailAzienda}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">P.Iva: {{quoteDetails.user.shipping.partitaIva}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">Codice U.: {{quoteDetails.user.shipping.codeDest}}</p>
                                </template>
                                <template v-else>
                                    <p class="fw-medium mb-2" id="billing-name">{{quoteDetails.user.ragSoc}}</p>
                                    <p class="text-muted mb-1" id="billing-address">{{quoteDetails.user.indirizzo}} - {{quoteDetails.user.citta}}</p>
                                    <p class="text-muted mb-1" id="billing-phone">Tel: {{quoteDetails.user.phone}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">Email: {{quoteDetails.user.emailAzienda}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">P.Iva: {{quoteDetails.user.partitaIva}}</p>
                                    <p class="text-muted mb-0" id="billing-tax">Codice U.: {{quoteDetails.user.codeDest}}</p>                                    
                                </template>
                            </div>
                            <!--end col-->
                        </div>
                        <!--end row-->
                    </div>
                    <!--end card-body-->
                    <div class="card-body p-4">
                        <div class="table-responsive">
                            <table class="table table-borderless text-center table-nowrap align-middle mb-0">
                                <thead>
                                    <tr class="table-active">
                                        <th scope="col" style="width: 50px;">#</th>
                                        <th scope="col">Dettaglio prodotto</th>
                                        <th scope="col">Prezzo U.</th>
                                        <th scope="col">Quantità</th>
                                        <th scope="col" class="text-end">Totale</th>
                                    </tr>
                                </thead>
                                <tbody id="products-list">
                                    
                                    <template v-if="quoteDetails.rows">
                                        <tr v-for="item of quoteDetails.rows" :key="item._id">
                                        <th scope="row">{{item.gcode}}</th>
                                        <td class="text-start">
                                            <span class="fw-medium">{{item.product[0].Descrizione}}</span>
                                            <p class="text-muted mb-0">{{item.product[0].ean}}
                                            </p>
                                        </td>
                                        <td>
                                            <template v-if="checkVar(item.detail_item.prezzoScontato2)=='number'">
                                                    &euro;<span id="ticket_price" class="product-price">{{formatPrice(item.detail_item.prezzoScontato2)}}</span>
                                                </template>
                                            <template v-else>
                                                    <template v-if="checkVar(item.detail_item.prezzoScontato1)=='number'">
                                                    &euro;<span id="ticket_price" class="product-price">{{formatPrice(item.detail_item.prezzoScontato1)}}</span>
                                                    </template>
                                            </template>
                                        </td>
                                        <td>{{item.detail_item.qta}}</td>
                                        <td class="text-end">{{ formatPrice(item.detail_item.prezzo) }}</td>
                                    </tr>
                                    </template>

                                    <tr class="border-top border-top-dashed mt-2">
                                        <td colspan="3"></td>
                                        <td colspan="2" class="fw-medium p-0">
                                            <table
                                                class="table table-borderless text-start table-nowrap align-middle mb-0">
                                                <tbody>
                                                    <!--<tr>
                                                        <td>Sub Total</td>
                                                        <td class="text-end">${{sub_total}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Estimated Tax (12.5%)</td>
                                                        <td class="text-end">${{estimated_tax}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Discount <small class="text-muted">(VELZON15)</small></td>
                                                        <td class="text-end">- ${{discount}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Shipping Charge</td>
                                                        <td class="text-end">${{shipping_charge}}</td>
                                                    </tr>-->
                                                    <tr class="border-top border-top-dashed">
                                                        <th scope="row"><h5>Totale</h5></th>
                                                        <td class="text-end">
                                                            <h5>
                                                                <span class="fw-semibold" id="cart-total">
                                                                &euro;<span class="product-line-price">{{ formatPrice(quoteDetails.totalPrice) }}</span>
                                                                </span>
                                                            </h5>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <!--end table-->
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <!--end table-->
                        </div>
                        <div class="mt-3">

                        </div>
                        <div class="mt-4">
                            <div class="mt-50">

                            </div>
                        </div>
                        <div class="hstack gap-2 justify-content-end d-print-none mt-4">

                        </div>
                    </div>
                    <!--end card-body-->
                </div>
                <!--end card-->
                <div class="card" id="demo">
                    <div >
                            <div class="mt-10 mr-20">
                    <div class="hstack gap-2 justify-content-end d-print-none mt-4 mb-4">
                            <a href="javascript:window.print()" class="btn btn-success"><i
                                    class="ri-printer-line align-bottom me-1"></i> Stampa</a>
                            <a href="javascript:void(0);" class="btn btn-danger" @click="convertHTMLToPDF()">
                                <i class="ri-download-2-line align-bottom me-1"></i> Apri in PDF</a>
                        </div>
                            </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </Layout>
</template>